<template>
  <div class="unselectable" @click="clickButton()">
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <div class="text-h1">
          <span> Test your Clickspeed </span>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center">
      <v-col md="1">
        <div
          :class="`rounded-sm`"
          class="pa-4 text-center secondary text-no-wrap"
        >
          <span class="white--text">{{ buttonClicked }}</span>
        </div>
      </v-col>

      <v-col md="1">
        <div
          :class="`rounded-sm`"
          class="pa-4 text-center secondary text-no-wrap"
        >
          <span class="white--text">CPS </span> <br />
          <span class="white--text">{{ clicksPerSecond }}</span>
        </div>
      </v-col>
      <v-col md="1">
        <div
          :class="`rounded-sm`"
          class="pa-4 text-center secondary text-no-wrap"
        >
          <span class="white--text">Highest CPS </span> <br />
          <span class="white--text">{{ highestCps }}</span>
        </div>
      </v-col>
      <v-col md="1">
        <div
          :class="`rounded-sm`"
          class="pa-4 text-center secondary text-no-wrap"
        >
          <span class="white--text">Lowest CPS </span> <br />
          <span class="white--text" v-if="lowestCps == 1000"> 0 </span>
          <span class="white--text" v-else> {{ lowestCps }} </span>
        </div>
      </v-col>
    </v-row>

    <v-row style="height: 75vh">
      <v-col class="d-flex align-end">
        <v-sparkline
          :value="value"
          :gradient="gradient"
          :smooth="radius || false"
          :padding="padding"
          :line-width="width"
          :stroke-linecap="lineCap"
          :gradient-direction="gradientDirection"
          :fill="fill"
          :type="type"
          :auto-line-width="autoLineWidth"
          :auto-draw-duration="0"
        >
        </v-sparkline>
      </v-col>
    </v-row>
  </div>
</template>

<script>
Array.prototype.max = function () {
  return Math.max.apply(null, this);
};
Array.prototype.min = function () {
  return Math.min.apply(null, this);
};
export default {
  name: "Test",

  data() {
    return {
      buttonClicked: 0,
      clicksPerSecond: 0,
      epoch: null,
      highestCps: 0,
      lowestCps: 1000,

      width: 2,
      radius: 15,
      padding: 8,
      lineCap: "round",
      gradient: ["#f72047", "#ffd200", "#1feaea"],
      value: [],
      gradientDirection: "top",
      fill: true,
      type: "trend",
      autoLineWidth: false,
    };
  },

  methods: {
    clickButton() {
      this.buttonClicked += 1;
      let time = Date.now() - this.epoch;
      this.epoch = Date.now();
      if (this.buttonClicked > 1) {
        this.clicksPerSecond = Math.round((1000 / time) * 100) / 100;
        this.value.push(this.clicksPerSecond);
        if (this.clicksPerSecond > this.highestCps) {
          this.highestCps = this.clicksPerSecond;
        }
        if (this.clicksPerSecond < this.lowestCps) {
          this.lowestCps = this.clicksPerSecond;
        }
      }
    },
  },
};
</script>

<style scoped></style>
